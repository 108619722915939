import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from 'assets/search.svg';
import styles from './searchBar.module.scss';

const SearchBarMarket = ({ placeholder, query = '', name, setQuery, height, onBlur, onBlurText, setCategorias }) => {


  const searchItems = (searchValue) => {

    if (searchValue.length > 0) {
      setCategorias(null)
    }
    setQuery(searchValue);

  };

  console.log()

  return (
    <div className={`${styles.search} ${height && styles[height]}`}>
      <img src={searchIcon} alt="search icon" className={styles.icon} />
      <input
        onKeyDown={(e) => {

          if (e.key === "Enter")
            onBlurText();
        }}
        //onFocus={onBlur}
        onBlur={onBlurText}
        className={styles.input}
        type="search"
        placeholder={placeholder}
        name={name}
        value={query}
        onChange={(e) => searchItems(e.target.value)}
      />
    </div>
  );
};

SearchBarMarket.propTypes = {
  placeholder: PropTypes.string,
  setQuery: PropTypes.func,
  query: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.string,
};

export default SearchBarMarket;
